
import { defineComponent } from "vue";
import MyNavbar from "./components/MyNavbar.vue";
import MyFooter from "./components/MyFooter.vue"; // Make sure this path is correct

export default defineComponent({
  components: {
    MyNavbar,
    MyFooter, // Your footer is now available to use in the template
  },
});
