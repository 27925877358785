<template>
  <footer class="bg-white text-black py-6">
    <div class="container mx-auto px-4">
      <!-- Title -->
      <div class="font-bold mb-4">Sentience Game Stats</div>

      <!-- Details -->
      <div class="text-sm font-normal mb-4">
        CEO Hyeyon Kwon | Business Registration Number 331-81-00223 | Tel
        +82-2-2135-8804 | Address 313, Nonhyeon-ro, Gangnam-gu, Seoul, Korea
      </div>

      <!-- Copyright -->
      <div>ⓒ Sentience, All Rights Reserved</div>
    </div>
  </footer>
</template>

<script>
export default {};
</script>

<style scoped>
.bg-darkBlue-800 {
  background-color: #002244; /* Dark blue color as mentioned */
}
</style>
