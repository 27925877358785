import { createRouter, createWebHistory } from "vue-router";
import GameList from "../components/GameList.vue";
import GameDetails from "../components/GameDetails.vue";
import MyCalendar from "../components/MyCalendar.vue"; // import the Calendar component
import MarketingTool from "../components/MarketingTool.vue";

const routes = [
  {
    path: "/",
    name: "GameList",
    component: GameList,
  },
  {
    path: "/game/:id",
    name: "GameDetails",
    component: GameDetails,
    props: true,
  },
  {
    path: "/calendar",
    name: "MyCalendar",
    component: MyCalendar,
  },

  {
    path: "/marketing-tool",
    name: "MarketingTool",
    component: MarketingTool
  },
 
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
