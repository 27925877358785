<template>
  <div class="container mx-auto mt-10 px-2">
    <div class="flex justify-between mb-5">
      <button
        @click="goToPreviousMonth"
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Prev
      </button>
      <span class="text-xl">{{ currentMonth }} {{ currentYear }}</span>
      <button
        @click="goToNextMonth"
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Next
      </button>
    </div>

    <table class="table-fixed w-full">
      <thead>
        <tr class="border-b-2 border-gray-200">
          <th v-for="day in daysOfWeek" :key="day" class="w-1/7 py-3">
            {{ day }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="week in weeks"
          :key="week[0].getTime()"
          class="border-b border-gray-200"
        >
          <td
            v-for="day in week"
            :key="day.getTime()"
            :class="{ 'bg-blue-200': isToday(day) }"
            class="py-3 text-center"
          >
            {{ day.getDate() }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { ref, computed } from "vue";

export default {
  name: "MyCalendar",
  setup() {
    const today = new Date();
    const currentMonth = ref(today.getMonth());
    const currentYear = ref(today.getFullYear());
    const daysOfWeek = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const weeks = computed(() => {
      const firstDay = new Date(
        currentYear.value,
        currentMonth.value,
        1
      ).getDay();
      const daysInMonth =
        32 - new Date(currentYear.value, currentMonth.value, 32).getDate();

      const weeks = [];
      let week = [];

      // Fill in days of the week before the start of the month
      for (let i = 0; i < firstDay; i++) {
        week.push(
          new Date(currentYear.value, currentMonth.value, -firstDay + i + 1)
        );
      }

      for (let day = 1; day <= daysInMonth; day++) {
        week.push(new Date(currentYear.value, currentMonth.value, day));

        if (week.length === 7) {
          weeks.push(week);
          week = [];
        }
      }

      // Fill in days of the week after the end of the month
      while (week.length < 7) {
        week.push(
          new Date(currentYear.value, currentMonth.value + 1, 7 - week.length)
        );
      }
      weeks.push(week);

      return weeks;
    });

    const goToNextMonth = () => {
      if (currentMonth.value === 11) {
        currentMonth.value = 0;
        currentYear.value++;
      } else {
        currentMonth.value++;
      }
    };

    const goToPreviousMonth = () => {
      if (currentMonth.value === 0) {
        currentMonth.value = 11;
        currentYear.value--;
      } else {
        currentMonth.value--;
      }
    };

    const isToday = (day) => {
      return (
        day.getDate() === today.getDate() &&
        day.getMonth() === today.getMonth() &&
        day.getFullYear() === today.getFullYear()
      );
    };

    return {
      currentMonth: computed(() => months[currentMonth.value]),
      currentYear,
      daysOfWeek,
      weeks,
      goToNextMonth,
      goToPreviousMonth,
      isToday,
    };
  },
};
</script>

<style scoped>
.td {
  width: 40px;
  height: 40px;
  text-align: center;
  vertical-align: middle;
}

.today {
  background-color: #dcdcdc;
}
</style>
